<template>
  <div>
    <ViewLoadingMessage :loading="firstLoading"/>

    <CRow v-if="! firstLoading">
      <CCol lg="12">
        <CCard class="no-borders">
          <CCardHeader class="mb-4">
            <h1 class="main-header mr-3" v-translate>Orders</h1>
            <DatePickerTimeZone
                class=""
                :timeZoneValue="timeZonePickerValue"
                @input="onDatePickerInput"
                :extraTimezones="customTimezoneList"
                :showDatePicker="false"
                :showTimeZonePicker="showTimeZonePicker"
                :showTimeZoneText="!showTimeZonePicker"
                :key="datePickerTimeZoneKey"
            />
            <template v-if="filtersApplied">
              <span class="mr-3" v-translate>Founded %{ totalResultsNumberFormat } results</span>

              <CButton @click="clearFilters" color="secondary" size="sm">
                <translate>Reset</translate>
              </CButton>
            </template>
            <div class="card-header-actions">
              <events-selector @on-apply="onSelectPicaServices"
                               @on-data="onSelectPicaServicesDataReady"
                               v-if="showEventSelector"/>
              <PButton class="btn btn-primary btn-sm ml-2" style="margin-right: 5px"
                       @click="exportOrders"
                       :loading="exporting"
                       :loading-text="$gettext('Generating file...')"
                       spinner-color="secondary"
                       :disabled="!hasResults">
                <CIcon width="30" name="cipFileDownload02" style="vertical-align: sub" class="mr-2"/>
                <translate>Export</translate>
              </PButton>

            </div>
          </CCardHeader>

          <div class="order-list-totals d-flex mb-4" v-if="showTotals">
            <div v-if="ordersTotal.total_amount">
              <div class="d-flex align-items-center">
                <CIcon name="cipCreditCard02" size="custom-size" width="40" height="40"
                       class="mr-3 icon-circle icon-circle--primary"/>
                <div>
                  <div class="label">
                    <translate>Total amount</translate>
                  </div>
                  <div class="value">{{ ordersTotal.total_amount|currency(ordersTotal.currency) }}</div>
                </div>
              </div>

            </div>
            <div v-if="ordersTotal.amount_net_photo_service && ordersTotal.platform_fee_additional">
              <div class="label">
                <translate>Total net amount</translate>
              </div>
              <div class="value">{{ ordersTotal.amount_net_photo_service|currency(ordersTotal.currency) }}</div>
            </div>
            <div v-if="ordersTotal && ordersTotal.amount_net_photo_service">
              <div class="label">
                <translate>Total net amount + fees</translate>
              </div>
              <div class="value">{{
                  (ordersTotal.amount_net_photo_service +
                      ordersTotal.platform_fee_additional)|currency(ordersTotal.currency)
                }}
              </div>
            </div>
          </div>

          <CCardBody>
            <CDataTable
                :items="items"
                :fields="fields"
                :hover="false"
                :striped="true"
                :bordered="true"
                :small="false"
                :fixed="false"
                :loading="loading"
                :sorter='{ external: true, resetable: false }'
                :sorter-value.sync="sorterValue"
                :no-items-view="{ noResults: $gettext('No filtering results available'), noItems: $gettext('No orders found') }"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value="columnFilterValue"
                @update:column-filter-value="filterResults($event)"
                @update:sorter-value="fetchResults({})"
                class="custom-table custom-table--has-calendar"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h6 v-translate>No orders found</h6>
                </div>
              </template>

              <template #order_number="{ item }">
                <td><CLink :to="routeToDetail(item)" class="link--order">{{ item.order_number }}</CLink></td>
              </template>

              <template #created_at="{ item }">
                <td>{{ item.created_at|formatDateTimeTZ(timeZone, 'L LT') }}</td>
              </template>
              <template #paid_at="{ item }">
                <td>
                  <span v-if="item.paid_at">{{ item.paid_at|formatDateTimeTZ(timeZone, 'L LT') }}</span>
                  <span v-else>N/A</span>
                </td>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>

              <template #created_at-filter="{ item }">
                <div>
                  <VueCtkDateTimePicker
                      v-model="customFilter.filterCreatedAtRange.start"
                      :auto-close="false"
                      @validate="filterByCreatedAtRange()"
                      @input="checkFilterByCreatedAtRange($event, 'start')"
                      :range="false"
                      :no-button="false"
                      :no-shortcuts="true"
                      :input-size="`sm`"
                      :position="`bottom`"
                      :no-label="true"
                      :only-date="true"
                      :label="$gettext('From date')"
                      format="YYYY-MM-DD"
                      formatted="L"
                      :right="false"
                      :no-header="true"
                      :locale="language"
                      :no-button-now="false"/>
                </div>
                <div class="mt-1" v-if="customFilter.filterCreatedAtRange.start">
                  <VueCtkDateTimePicker
                      v-model="customFilter.filterCreatedAtRange.end"
                      :auto-close="false"
                      @validate="filterByCreatedAtRange()"
                      @input="checkFilterByCreatedAtRange($event, 'end')"
                      :range="false"
                      :no-button="false"
                      :no-shortcuts="true"
                      :input-size="`sm`"
                      :position="`bottom`"
                      :no-label="true"
                      :only-date="true"
                      :label="$gettext('To date')"
                      format="YYYY-MM-DD"
                      formatted="L"
                      :min-date="customFilter.filterCreatedAtRange.start ? customFilter.filterCreatedAtRange.start : null"
                      :right="false"
                      :no-header="true"
                      :locale="language"
                      :no-button-now="false"/>
                </div>
              </template>

              <template #paid_at-filter="{ item }">
                <div>
                  <VueCtkDateTimePicker
                      v-model="customFilter.filterPaidAtRange.start"
                      id="dateRange"
                      :auto-close="false"
                      @validate="filterByPaidAtRange()"
                      @input="checkFilterByPaidAtRange($event)"
                      :range="false"
                      :no-button="false"
                      :no-shortcuts="true"
                      :input-size="`sm`"
                      :position="`bottom`"
                      :no-label="true"
                      :only-date="true"
                      :label="$gettext('From date')"
                      format="YYYY-MM-DD"
                      formatted="L"
                      :right="false"
                      :no-header="true"
                      :locale="language"
                      :no-button-now="false"/>
                </div>
                <div class="mt-1" v-if="customFilter.filterPaidAtRange.start">
                  <VueCtkDateTimePicker
                      v-model="customFilter.filterPaidAtRange.end"
                      id="dateRange"
                      :auto-close="false"
                      @validate="filterByPaidAtRange()"
                      @input="checkFilterByPaidAtRange($event)"
                      :range="false"
                      :no-button="false"
                      :no-shortcuts="true"
                      :input-size="`sm`"
                      :no-label="true"
                      :position="`bottom`"
                      :only-date="true"
                      :label="$gettext('To date')"
                      format="YYYY-MM-DD"
                      formatted="L"
                      :min-date="customFilter.filterPaidAtRange.start ? customFilter.filterPaidAtRange.start : null"
                      :right="false"
                      :no-header="true"
                      :locale="language"
                      :no-button-now="false"/>
                </div>
              </template>

              <template #order_id="{ item }">
                <td v-if="item.order_id">{{ item.order_id }}</td>
                <td v-else>-</td>
              </template>

              <template #user="{ item }">
                <td v-if="item.user">{{ item.user.email }}</td>
                <td v-else>-</td>
              </template>

              <template #payment_method="{ item }">
                <td>
                  <span v-if="paymentMethods[item.payment_method]">{{
                      paymentMethods[item.payment_method].label
                    }}</span>
                </td>
              </template>

              <template #payment_method-filter="{ item }">

                <CSelect
                    size="sm"
                    class="filter-amount--type"
                    :options="paymentMethodsList"
                    :value.sync="customFilter.filterPaymentMethod"
                    @change="filterByPaymentMethod"
                />

              </template>

              <template #status="{ item }">
                <td class="text-nowrap">
                  <CBadge class="badge-outline" shape="pill" color="light" v-if="orderStatuses && item.status">
                    <div class="d-inline-flex align-items-center py-1">
                      <CIcon name="cipDot" :class="getStatusColor(item.status)" size="custom-size" :height="6"/>
                      <span class="ml-1">{{ getOrderStatusByValue(item.status) }}</span>
                    </div>
                  </CBadge>
                  <CSpinner class="ml-2" grow size="sm" color="primary"
                            v-if="item.order_id === loadingStatusChangeId"/>
                </td>
              </template>
              <template #during_stay="{ item }">
                <td class="text-nowrap">
                  <CIcon :name="item.during_stay ? 'cipDotGreen':'cipDotGray'"
                         size="custom-size" :height="10"/>
                </td>
              </template>

              <template #status-filter="{ item }">
                <CSelect
                    size="sm"
                    class="mb-0"
                    :placeholder="$gettext('select')"
                    :options="orderStatuses"
                    :value.sync="customFilter.filterOrderStatus"
                    @update:value="filterByOrderStatus($event)"
                />
              </template>
              <template #order_type-filter="{ item }">
                <CSelect
                    size="sm"
                    class="mb-0"
                    :placeholder="$gettext('select')"
                    :options="ordersTypes"
                    :value.sync="customFilter.filterOrderType"
                    @update:value="filterByOrderType($event)"
                />
              </template>
              <template #total_amount-filter="{ item }">
                <div class="filter-amount d-flex">
                  <CSelect
                      size="sm"
                      class="filter-amount--type"
                      :options="filterAmountOptions"
                      :value.sync="customFilter.filterTotalAmountType"
                      @change="filterTotalAmountTypeChange($event)"
                  />
                  <CInput
                      size="sm"
                      type="number"
                      class="filter-amount--value"
                      :value.sync="customFilter.filterTotalAmount"
                      @keyup.enter.native="filterByTotalAmount($event)"
                  />
                </div>
              </template>

              <template #total_amount="{item}">
                <td>
                  {{ item.total_amount|currency(item.currency) }}
                </td>
              </template>
              <template #amount_tax="{item}">
                <td>
                  {{ item.amount_tax|currency(item.currency) }}
                </td>
              </template>

              <template #platform_fee_total="{item}">
                <td>
                  <div>
                    {{ item.platform_fee_total|currency(item.currency) }}
                  </div>
                  <div class="small text-nowrap" v-if="item.platform_fee_pica > 0 && item.platform_fee_additional > 0">
                    (Pica {{ item.platform_fee_pica|currency(item.currency) }})
                  </div>
                  <div class="small text-nowrap" v-if="item.platform_fee_additional > 0">
                    (
                    <translate>Additional</translate>
                    : {{ item.platform_fee_additional|currency(item.currency) }})
                  </div>
                </td>
              </template>

              <template #payment_transaction_fee="{ item }">
                <td>
                  {{
                    item.payment_transaction_fee
                        | transactionFilterShared(item)
                        | currency(item.currency)
                  }}
                </td>
              </template>
              <template #payment_transaction_fee_on_pica="{ item }">
                <td>
                  {{
                    item.payment_transaction_fee
                        | transactionFilterOnPica(item)
                        | currency(item.currency)
                  }}
                </td>
              </template>

              <template #amount_net_photo_service-filter="{ item }">
                <div class="filter-amount d-flex">
                  <CSelect
                      size="sm"
                      class="filter-amount--type"
                      :options="filterAmountOptions"
                      :value.sync="customFilter.filterAmountNetPhotoServiceType"
                      @change="filterAmountNetPhotoServiceTypeChange($event)"
                  />
                  <CInput
                      size="sm"
                      type="number"
                      class="filter-amount--value"
                      :value.sync="customFilter.filterAmountNetPhotoService"
                      @keyup.enter.native="filterByAmountNetPhotoService($event)"
                  />
                </div>
              </template>

              <template #amount_net_photo_service="{item}">
                <td>
                  {{ item.amount_net_photo_service|currency(item.currency) }}
                </td>
              </template>

              <template #picacode="{ item }">
                <td>
                  <CBadge color="primary" shape="pill" class="badge-outline pica-code pica-code--sm">
                    <div class="d-inline-flex align-items-center py-1">
                      <CIcon name="cipQrCode01" size="custom-size" :height="12"/>
                      <span class="ml-1">{{ item.picacode }}</span>
                    </div>
                  </CBadge>
                </td>
              </template>

              <template #actions="{ item, index }">
                <td class="text-nowrap sticky-column">
                  <CButton v-if="hasPerm('orders.detail') && item.order_id"
                           color="dark"
                           variant="outline"
                           class="btn-circle btn-circle--sm d-inline-flex align-items-center justify-content-center"
                           size="sm"
                           :to="routeToDetail(item)">
                    <CIcon name="cipArrowRight"/>
                  </CButton>

                  <!--<CDropdown
                      v-if="(hasPerm('pica_ecommerce.change_order')) && (item.payment_method !== null && paymentMethods[item.payment_method].name === Constants.ORDER_PAYMENT_METHOD_BANK_TRANSFER) && (item.status !== orderStatus.PAID)"
                      :customPopperOptions="{strategy: 'fixed', placement: 'bottom-end'}"
                      class="no-arrow d-inline-block dd-order-list">
                    <template #toggler-content>
                      <CIcon name="cip-more"/>
                    </template>
                    <CDropdownItem @click="setAsPaid(item, index)">
                      <translate>Set as paid</translate>
                    </CDropdownItem>
                  </CDropdown>-->
                </td>
              </template>
            </CDataTable>
          </CCardBody>

          <CCardFooter v-if="totalPages > 1">
            <CPagination
                align="center"
                @update:activePage="onUpdatePage"
                :active-page="activePage"
                :pages="totalPages "/>
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import OrderService from "@/services/order.service"
import Constants, {orderStatus} from "@/constants"
import {arrayToObject} from "@/utils/functions";
import PButton from "@/components/PButton.vue";
import {mapActions, mapGetters} from "vuex";
import {ordersFields} from "@/views_pica/orders/constants";
import EventsSelector from "@/components/EventsSelector.vue";
import DatePickerTimeZone from "@/components/DatePickerTimeZone.vue";
import {layoutMixin} from "@/utils/mixins";
import _ from "lodash";
import {filterAmountType} from "@/domain/orders/store";



export default {
  name: 'OrderList',
  mixins: [layoutMixin],
  components: {
    EventsSelector,
    PButton,
    ViewLoadingMessage,
    DatePickerTimeZone
  },

  data() {
    return {
      datePickerTimeZoneKey: 0,
      Constants,
      loading: false,
      firstLoading: true,

      items: [],
      ordersTotal: null,

      sorterValue: {"asc": false, "column": "order_id"},

      loadingStatusChangeId: null,
      exporting: false,

      pagination: {},

      picaServices: [],

      timeZone: "Europe/Rome",
      timeZonePickerValue: null,
      datePickerInitialValue: null
    }
  },

  async mounted() {


    if (this.isInEventOrders) {
      // filter orders on payed for IGO
      if (this.isOwnerIGD) this.columnFilterValue.filterOrderStatus = this.orderStatus.PAID
    }


    this.resetTimeZone()
    await Promise.all([
      this.fetchConfig({type: 'order'}),
      this.fetchOrderStatus(),
      this.fetchPaymentMethods()
    ])
    await this.fetchResults()
    this.firstLoading = false

    await this.$nextTick()
    this.handleTableScroll(this.$el.querySelector('.table-responsive'))
  },

  computed: {
    ...mapGetters('orders', ['columnFilterValue', 'activePage', 'customFilter']),
    ...mapGetters('event', [
      'isInEvent',
      'picaServiceId',
      'isResort',
      'isOwnerIGD',
      'askFaceValidityStart',
      'askFaceValidityEnd',
      'assignsTransactionCostsToPica'
    ]),
    ...mapGetters('event', {eventTimeZone: 'timeZone'}),
    ...mapGetters('core', {
      coreOrderStatus: 'orderStatus',
      corePaymentMethods: 'paymentMethods'
    }),
    orderStatuses() {
      return [{value: null, label: this.$gettext('Any')}, ...this.coreOrderStatus]
    },
    ordersTypes() {
      return [
          {value: null, label: this.$gettext('Any')},
          {value: 'photolist', label: this.$gettext('photolist')},
          {value: 'photopackage', label: this.$gettext('photopackage')},
          {value: 'video', label: this.$gettext('video')},]
    },
    showTotals() {
      return this.ordersTotal && (this.ordersTotal.total_amount || this.ordersTotal.amount_net_photo_service || this.ordersTotal.platform_fee_additional)
    },
    showTimeZonePicker() {
      return this.hasPerm('dashboard.view_timezone_select')
    },
    customTimezoneList() {
      if (!this.isInEventOrders) {
        return this.picaServices.map(event => event.timezone || "Europe/Rome")
      }
      return [this.eventTimeZone || "Europe/Rome"]
    },
    orderStatus() {
      return orderStatus
    },
    showEventSelector() {
      return !this.isInEventOrders
    },
    selectedPicaServices() {
      return this.picaServices.filter(x => x.checked === true)
    },
    picaServicesIds() {
      return this.selectedPicaServices.map(x => x.id)
    },


    filterAmountOptions() {
      return Object.keys(filterAmountType).map(key => filterAmountType[key])
    },

    page: {
      get() {
        return this.pagination.page_number
      },
      set(val) {
        return val
      }
    },
    totalResults() {
      return this.pagination.total
    },
    totalResultsNumberFormat() {
      return this.$options.filters.number(this.pagination.total)
    },

    hasResults() {
      return this.totalResults > 0
    },

    totalPages() {
      return this.pagination.num_pages
    },

    paymentMethods() {
      const arrPaymentMethods = this.corePaymentMethods.map(i => ({
        'id': i.id,
        'value': i.id,
        'name': i.name,
        'label': i.label
      }))
      return arrayToObject(arrPaymentMethods)
    },

    paymentMethodsList() {
      if(!this.paymentMethods) return []

      const  paymentMethods = Object.values(this.paymentMethods).sort((a, b) => a.label.localeCompare(b.label))
      return [{value: null, label: this.$gettext('Any')}, ...paymentMethods]
    },

    isInEventOrders() {
      return this.isInEvent && this.picaServiceId
    },

    defaultFilterParams() {
      const paymentMethod = {
        'payment_method': this.paymentMethodsList.filter(x => x.name !== 'external').map(item => item.id).join()
      }

      return {...paymentMethod}
    },
    fields() {
      let filteredFields = Object.values(ordersFields)
      if (this.isInEventOrders) {
        filteredFields = filteredFields.filter(x => x.key !== 'event_name')
      }
      if (!this.hasPerm('orders.fields.id'))
        filteredFields = filteredFields.filter(x => x.key !== 'order_id')
      if (!this.hasPerm('orders.fields.order_user'))
        filteredFields = filteredFields.filter(x => x.key !== 'user')
      if (!this.hasPerm('orders.fields.pica_code'))
        filteredFields = filteredFields.filter(x => x.key !== 'picacode')
      if (!this.hasPerm('orders.fields.during_stay') || !this.isInEvent || !this.askFaceValidityStart)
        filteredFields = filteredFields.filter(x => x.key !== 'during_stay')
      if (!this.hasPerm('orders.fields.status'))
        filteredFields = filteredFields.filter(x => x.key !== 'status')
      if (!this.hasPerm('orders.fields.total_amount'))
        filteredFields = filteredFields.filter(x => x.key !== 'total_amount')
      if (!this.hasPerm('orders.fields.amount_tax'))
        filteredFields = filteredFields.filter(x => x.key !== 'amount_tax')
      if (!this.hasPerm('orders.fields.platform_fee_total'))
        filteredFields = filteredFields.filter(x => x.key !== 'platform_fee_total')
      if (!this.hasPerm('orders.fields.net_photo_service'))
        filteredFields = filteredFields.filter(x => x.key !== 'amount_net_photo_service')
      if (!this.hasPerm('orders.fields.transaction_fee')) {
        filteredFields = filteredFields.filter(x => x.key !== 'payment_transaction_fee')
        filteredFields = filteredFields.filter(x => x.key !== 'payment_transaction_fee_on_pica')
      }

      if (this.isInEventOrders) {
        if (this.assignsTransactionCostsToPica) {
          filteredFields = filteredFields.filter(x => x.key !== 'payment_transaction_fee')
        } else {
          filteredFields = filteredFields.filter(x => x.key !== 'payment_transaction_fee_on_pica')
        }
      }
      return filteredFields
    },
    filtersApplied() {
      const filters = Object.keys(this.columnFilterValue).reduce((acc, key) => {
        if (this.columnFilterValue[key] !== '') {
          acc[key] = this.columnFilterValue[key]
        }
        return acc
      }, {})

      return (Object.keys(filters).length > 0 ||
          this.customFilter.filterPaymentMethod ||
          !_.isEmpty(this.customFilter.filterCreatedAtRange.start) ||
          !_.isEmpty(this.customFilter.filterCreatedAtRange.end) ||
          !_.isEmpty(this.customFilter.filterPaidAtRange.start) ||
          !_.isEmpty(this.customFilter.filterPaidAtRange.end) ||
          this.customFilter.filterTotalAmount ||
          this.customFilter.filterAmountNetPhotoService ||
          this.customFilter.filterOrderType ||
          this.customFilter.filterOrderStatus) && !this.loading
    }
  },
  filters: {
    transactionFilterOnPica(value, item) {
      return item.is_payment_transaction_fee_on_pica ? item.payment_transaction_fee : 0.0
    },
    transactionFilterShared(value, item) {
      return item.is_payment_transaction_fee_on_pica ? 0.0 : item.payment_transaction_fee
    }
  },
  methods: {
    ...mapActions('orders', ['updateCustomFilter', 'updateColumnFilterValue', 'updateActivePage']),
    ...mapActions('login', ['fetchConfig']),
    ...mapActions('core', ['fetchOrderStatus', 'fetchPaymentMethods']),
    forceRenderDatePicker() {
      this.datePickerTimeZoneKey += 1;
    },
    onDatePickerInput(value) {
      this.timeZone = value.timezone
      this.fetchResults()
    },

    getParams(params) {
      params = {...params, ...this.defaultFilterParams}

      if (this.customFilter.filterPaymentMethod) params.payment_method = this.customFilter.filterPaymentMethod
      if (this.customFilter.filterOrderStatus) params.status = this.customFilter.filterOrderStatus
      if (this.customFilter.filterOrderType) params.order_type = this.customFilter.filterOrderType

      // Filter total amount
      if (this.customFilter.filterTotalAmount && this.customFilter.filterTotalAmountType === filterAmountType.EQUAL)
        params.total_amount = this.customFilter.filterTotalAmount
      if (this.customFilter.filterTotalAmount && this.customFilter.filterTotalAmountType === filterAmountType.LTE)
        params.total_amount_to = this.customFilter.filterTotalAmount
      if (this.customFilter.filterTotalAmount && this.customFilter.filterTotalAmountType === filterAmountType.GTE)
        params.total_amount_from = this.customFilter.filterTotalAmount

      // filter amount net photo service
      if (this.customFilter.filterAmountNetPhotoService && this.customFilter.filterAmountNetPhotoServiceType === filterAmountType.EQUAL)
        params.amount_net_photo_service = this.customFilter.filterAmountNetPhotoService
      if (this.customFilter.filterAmountNetPhotoService && this.customFilter.filterAmountNetPhotoServiceType === filterAmountType.LTE)
        params.amount_net_photo_service_to = this.customFilter.filterAmountNetPhotoService
      if (this.customFilter.filterAmountNetPhotoService && this.customFilter.filterAmountNetPhotoServiceType === filterAmountType.GTE)
        params.amount_net_photo_service_from = this.customFilter.filterAmountNetPhotoService

      if (this.customFilter.filterCreatedAtRange.start) {
        params.created_at_from = this.$options.filters.isoStartOfDayFromStringDate(this.customFilter.filterCreatedAtRange.start, this.timeZone)
      }
      if (this.customFilter.filterCreatedAtRange.end) {
        params.created_at_to = this.$options.filters.isoEndOfDayFromStringDate(this.customFilter.filterCreatedAtRange.end, this.timeZone)
      }
      if (this.customFilter.filterPaidAtRange.start) {
        params.paid_at_from = this.$options.filters.isoStartOfDayFromStringDate(this.customFilter.filterPaidAtRange.start, this.timeZone)
      }
      if (this.customFilter.filterPaidAtRange.end) {
        params.paid_at_to = this.$options.filters.isoEndOfDayFromStringDate(this.customFilter.filterPaidAtRange.end, this.timeZone)
      }

      if (this.customFilter.filterCreatedAtRange || this.customFilter.filterPaidAtRange)
        params.tz = this.timeZone

      if (this.sorterValue.column) {
        params.o = `${this.sorterValue.asc ? '' : '-'}${this.sorterValue.column}`
      }

      // orders displayed into event
      if (this.isInEventOrders) {
        params.pica_service = this.picaServiceId
      } else {
        params.pica_service_ids = this.picaServicesIds
      }
      return params
    },

    async fetchResults(params = {}) {
      this.loading = true

      let paramsForTotal = this.getParams()
      delete paramsForTotal.o

      let responseOrderTotals = await OrderService.getOrdersTotals({...this.columnFilterValue, ...paramsForTotal})
      this.ordersTotal = responseOrderTotals.data

      let responseOrdersListing = await OrderService.getOrdersListing({...this.columnFilterValue, ...this.getParams(params)})
      const items = responseOrdersListing.data.results
      this.pagination = responseOrdersListing.data.pagination

      this.items = items
      this.loading = false
    },

    onUpdatePage(page) {
      this.updateActivePage(page)
      this.fetchResults({page})
    },

    filterByCreatedAtRange() {
      this.fetchResults()
    },

    checkFilterByCreatedAtRange(value, prop) {
      if (!value) {
        this.customFilter.filterCreatedAtRange[prop] = null
        if (!this.customFilter.filterCreatedAtRange.start) {
          this.customFilter.filterCreatedAtRange = {}
        }
        this.fetchResults()
      }
    },

    filterByPaidAtRange() {
      this.fetchResults()
    },

    checkFilterByPaidAtRange(value) {
      if (!value) {
        if (!this.customFilter.filterPaidAtRange.start) {
          this.customFilter.filterPaidAtRange = {}
        }
        this.fetchResults()
      }
    },

    filterByPaymentMethod(value) {
      this.fetchResults()
    },

    filterByOrderStatus(value) {
      this.fetchResults()
    },

    filterByOrderType(value) {
      this.fetchResults()
    },

    filterByTotalAmount(value) {
      if (!value) {
        this.filterTotalAmount = null
      }

      this.fetchResults()
    },

    filterTotalAmountTypeChange(value) {
      if (this.customFilter.filterTotalAmount) {
        this.fetchResults()
      }
    },

    filterAmountNetPhotoServiceTypeChange(value) {
      if (this.customFilter.filterAmountNetPhotoService) {
        this.fetchResults()
      }
    },

    filterByAmountNetPhotoService(value) {
      if (!value) {
        this.customFilter.filterAmountNetPhotoService = null
      }
      this.fetchResults()
    },

    resetTimeZone() {
      this.timeZone = this.eventTimeZone || "Europe/Rome"
      this.timeZonePickerValue = this.timeZone
      this.forceRenderDatePicker()
    },

    filterResults(value) {
      this.updateColumnFilterValue(value)
      this.fetchResults()
    },

    clearFilters() {
      this.updateColumnFilterValue({})
      this.updateCustomFilter(null)
      this.isOwnerIGD ? this.customFilter.filterOrderStatus = this.customFilter.orderStatus.PAID : null
      this.resetTimeZone()

      this.fetchResults()
    },

    getOrderStatusByValue(value) {
      let status = this.orderStatuses.find(i => i.value === value)
      if (status) return status.label
      return value
    },

    getStatusColor(value) {
      return {
        'created': 'text-secondary',
        'pending': 'text-warning',
        'paid': 'text-success',
        'gifted_pending': 'text-secondary',
        'gifted': 'text-secondary',
        'shipped': 'text-secondary',
        'refunded': 'text-secondary',
      }[value]
    },

    routeToDetail(item) {
      const routeName = this.isInEventOrders ? 'eventOrderDetail' : 'orderDetail'
      if (!item['order_id']) return null

      return {name: routeName, params: {orderId: item['order_id']}}
    },

    setAsPaid(item, index) {
      this.loadingStatusChangeId = item.order_id

      OrderService.setStatus(item.order_id, {
        "status": orderStatus.PAID
      })
          .then(response => {
            // TODO: check the javascript warning that occurs every time you change a value in the CDataTable
            this.$set(this.items[index], 'status', response.data.status)
            this.loadingStatusChangeId = null
          })
    },
    onSelectPicaServicesDataReady(picaServices) {
      this.picaServices = picaServices
    },
    onSelectPicaServices(picaServices) {
      this.picaServices = picaServices
      if (this.firstLoading) {
        return
      }
      this.fetchResults()
    },
    async exportOrders() {
      this.exporting = true
      await OrderService.exportOrders({...this.columnFilterValue, ...this.getParams()})
      this.exporting = false
    }
  },
  beforeDestroy() {
    if (!this.$route.path.includes('orders')) {
      this.updateColumnFilterValue({})
      this.updateCustomFilter(null)
      this.updateActivePage(1)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
