export const layoutMixin = {
  methods: {
    handleTableScroll(element) {
      if (!element) return;
      this.checkScrollPosition(element);
      element.addEventListener('scroll', () => {
        this.checkScrollPosition(element)
      });
    },
    checkScrollPosition(scrollContainer) {
      const offset = 10
      if (scrollContainer.scrollLeft < scrollContainer.scrollWidth - scrollContainer.clientWidth - offset) {
        scrollContainer.classList.add('scrolling')
      } else {
        scrollContainer.classList.remove('scrolling')
      }
    }
  }
}