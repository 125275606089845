import client_core from "@/services/clients/client_core";
import Downloader from "@/domain/core/utils/downloader";

const OrderService = {
    getOrdersListing(params) {
        return client_core.post('/dashboard/v1/orders/items/listing/', params)
    },


    getOrdersTotals(params) {
        return client_core.post('/dashboard/v1/orders/items/totals/', params)
    },

    async exportOrders(params) {
        const response = await client_core.post('/dashboard/v1/orders/items/export-csv/', params, {responseType: 'blob'})
        Downloader.downloadFileFromResponse(response)
    },

    retrieveOrder(orderId) {
        return client_core.get(`/dashboard/v1/orders/${orderId}/`)
    },

    setStatus(orderId, params) {
        return client_core.patch(`/dashboard/v1/orders/${orderId}/set-status/`, params)
    },

    replaceMedia(orderId, orderItemId, params) {
        return client_core.patch(`/dashboard/v1/orders/${orderId}/order-items/${orderItemId}/replace-media/`, params)
    },

    replaceAlbum(orderId, orderItemId, params) {
        return client_core.patch(`/dashboard/v1/orders/${orderId}/order-items/${orderItemId}/replace-album/`, params)
    },
}

export default OrderService