<template>
  <CDropdown
      :show.sync="show"
      placement="bottom-end"
      :caret="false"
      :offset="[-20,-20]"
      :add-toggler-classes="['btn-outline-dark', 'btn-circle', 'btn-sm']"
      class="services-selector"
  >
    <template #toggler-content>
      <CIcon name="cipSettings" class="m-0 eq"/>
    </template>
    <div class="services-selector__container">
      <div class="services-selector__head"><h5 v-translate>Select events</h5></div>
      <div class="services-selector__search">
        <CInput size="sm" block type="search" v-model="inputText"
                :class="{'has-res': showResults}"
                @input="onSearchPicaServices"
                @blur="onSearchBlur"
                @click="searchResults.length > 0 ? showResults = true: null"
        >
          <template #prepend-content>
            <CIcon name="cip-search2" size="custom" :height="14" style="color: #7E838D"/>
          </template>
        </CInput>
        <div class="services-selector__pills" v-if="queries.length>0">
          <ul class="list-inline m-0">
            <li v-for="(query, index) in queries" :key="index" class="list-inline-item mr-1 mb-1">
              <div  class="badge badge-pill badge-primary">
                {{query.label|truncate(10)}} <a href="#" @click.stop="removeQuery(query)" class="close-tag text-white">&times;</a></div>
            </li>
          </ul>
        </div>
        <div class="services-selector__results" v-show="showResults">
          <template v-if="searchResults.length>0">
            <div class="services-selector__item-s" v-for="(item, index) in searchResults" :key="index" :class="{'active': queryIsSelected(item)}">
              <a href="" @click.prevent="filterPicaServices(item)">
                <CIcon :name="getQueryIcon(item.type)" size="custom" :height="16" class="mr-1"/>
                {{ item.label }}
              </a>
            </div>
          </template>
          <template v-else>
            <div class="services-selector__item-s text-center">
              <translate>No results</translate>
            </div>
          </template>
        </div>
      </div>
      <div class="services-selector__tools">
        <CInputCheckbox v-if="picaServices"
                        custom
                        id="checkToggle"
                        :checked="allEventsAreChecked"
                        :label="allEventsAreChecked ? $gettext('Deselect all'): $gettext('Select all')"
                        @update:checked="toggleCheck($event)"/>
      </div>
      <div class="services-selector__scroller">
        <CListGroup flush class="services-selector__list">

          <CListGroupItem v-for="(item, key) in picaServices"
                          v-show="(item.industry === filterIndustry) || !filterIndustry"
                          class="flex-column align-items-start px-0 pt-0 pb-1 border-bottom-0"
                          :key="key">
            <div class="d-flex w-100">
              <CInputCheckbox
                  custom
                  :label="`${item.event_name || '-'} (${getIndustryConfig(item.industry).label})`"
                  :value="item.id"
                  :checked.sync="item.checked"
                  @update:checked="setPicaServices($event, item)"/>
            </div>
          </CListGroupItem>
        </CListGroup>
      </div>
      <div class="services-selector__footer text-center">
        <CButton :disabled="selectedPicaServices.length === 0" type="button"
                 @click="apply" size="sm" color="primary" block>
          <translate>Apply</translate>
          ({{ selectedPicaServices.length|number }})
        </CButton>
      </div>
    </div>
  </CDropdown>
</template>

<script>
import debounce from "lodash/debounce";
import StatsService from "@/services/stats.service";
import {getIndustryConfig} from "@/views_pica/stats/constants";

const queryType = {
  TITLE: 'title',
  TAG: 'tag',
  PIN: 'pin'
}

export default {
  name: "EventsSelector",
  emits: ['on-apply', 'on-data'],
  data() {
    return {
      show: false,
      showResults: false,
      inputText: '',
      searchResults: [],
      picaServices: [],
      queries:[]
    }
  },
  props: {
    filterIndustry:{
      type: String,
      required: false
    }
  },
  watch: {
    inputText: function (newVal, oldVal) {
      if (!newVal) {
        this.showResults = false
        //this.queries = []
        //this.searchResults = []
        //this.getPicaServices()
      }
    },
    selectedPicaServices:function(newVal, oldVal) {
      const checkToggle = document.getElementById('checkToggle')
      this.atLeastEventChecked ? checkToggle.indeterminate = true : checkToggle.indeterminate = false
    }
  },
  computed: {
    queryType(){
      return queryType
    },

    querySearch() {
      let queryObject = {}, finalQs = '?'
      
      this.queries.forEach(item => {
        let qs = item.query.slice(1).split('=')
        if(!queryObject[qs[0]]) queryObject[qs[0]] = []
        queryObject[qs[0]].push(qs[1])
      })
      
      Object.keys(queryObject).forEach(key => {
        finalQs += `${key}=${queryObject[key].join()}&`
      })

      return finalQs.slice(0, finalQs.length - 1)
    },

    selectedPicaServices() {
      if(this.filterIndustry)
        return this.picaServices.filter(x => x.checked === true && x.industry === this.filterIndustry)
      else
        return this.picaServices.filter(x => x.checked === true)
    },
    picaServicesByIndustry() {
      if(this.filterIndustry)
        return this.picaServices.filter(x => x.industry === this.filterIndustry)
      else
        return this.picaServices
    },
    allEventsAreChecked() {
      return this.selectedPicaServices.length === this.picaServicesByIndustry.length
    },
    atLeastEventChecked(){
       return this.selectedPicaServices.length < this.picaServicesByIndustry.length && this.selectedPicaServices.length > 0
    }
  },
  async mounted() {
    await this.getPicaServices()
    this.$emit('on-data', this.picaServices)
  },
  methods: {
    getIndustryConfig,
    getQueryIcon(type) {
      let iconName
      if (type === this.queryType.TITLE) iconName = 'cip-text'
      if (type === this.queryType.TAG) iconName = 'cip-tag2'
      if (type === this.queryType.PIN) iconName = 'cip-marker'
      return iconName
    },
    apply() {
      this.show = false
      this.$emit('on-apply', this.picaServices)
    },
    setPicaServices(event, item) {
      item.checked = event
    },
    filterPicaServices(item = {}) {
      this.showResults = false

      if(this.queries.filter(x=> x.query === item.query).length === 0)
        this.queries.push(item)

      this.getPicaServices()
    },
    async getPicaServices() {
      return StatsService.getPicaServices(this.querySearch).then(response => {
        this.picaServices = response.data
        this.picaServices = this.picaServices.map(el => el ? {...el, checked: true} : el);

      }).catch(error => console.log(error))
    },

    toggleCheck(value) {
      this.picaServicesByIndustry.map(el => {el.checked = value});
    },

    onSearchBlur(){
      setTimeout(()=>this.showResults=false, 200)
    },
    queryIsSelected(query){
      return !!this.queries.find(x => x === query)
    },
    removeQuery(query) {
      this.queries = this.queries.filter(x=> x.query !== query.query)
      this.getPicaServices()
      return false
    },

    onSearchPicaServices: debounce(async function () {
      if (this.inputText.length > 2) {
        StatsService.searchPicaServices({q: this.inputText}).then(response => {
          this.searchResults = response.data
          this.showResults = true
        }).catch(error => {
          console.log(error)
        })
      }
    }, 500),
  }
}
</script>
