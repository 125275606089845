<template>
  <div class="d-flex flex-row mr-3">
    <div class="d-flex" v-if="showDatePicker">
      <div>
        <div class="input-group input-group-sm input-group-date-time" style="width: 240px">
          <VueCtkDateTimePicker
            v-model="dateModel"
            @input="onDatePickerInput"
            @validate="onValidate"
            format="YYYY-MM-DD"
            formatted="L"
            :range="true"
            :auto-close="false"
            :no-button="false"
            :input-size="`sm`"
            :no-label="true"
            :color="color"
            :label="$gettext('Select a date range')"
            :custom-shortcuts="datePickerShortcuts"
            :locale="language"
            :no-button-now="true"
            :shortcut="shortcut"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <CIcon name="cipCalendar"></CIcon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row ml-2" v-if="showTimeZonePicker">
      <CSelect
      :options="customTimezoneList"
      :value="timeZoneValue"
      :size="'sm'"
      :placeholder="$pgettext('core.label','Select a timezone')"
      @input="onTimeZoneInput"
      :class="'in-picker'"
  />
    </div>
    <div class="d-flex align-items-end ml-3">
      <p class="small font-italic mb-0" v-if="showTimeZoneText">
        <TimeZoneInfo :timezone="timeZone"/>
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { datePickerShortcuts } from "@/views_pica/stats/constants";
import TimeZoneInfo from "@/domain/core/components/TimeZoneInfo.vue";
export default {
  components: {TimeZoneInfo},
  emits: ["input"],
  data() {
    return {
      dateModel: {},
      timeZone: null,
    };
  },
  props: {
    shortcut: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: "#1867FF",
    },
    timeZoneValue: {
      type: String,
      required: false,
    },
    /**
     * Set of timezones to be displayed verbatim in the timezone picker. Has
     * priority over the default and over extraTimezones.
     */
    timezones: {
      type: Array,
      required: false,
    },
    /**
     * Set of timezones to be added to the default ones (the browser timezone
     * and UTC).
     */
    extraTimezones: {
      type: Array,
      required: false,
    },
    showDatePicker: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTimeZonePicker: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTimeZoneText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.ignoreEvents = true;
  },
  mounted() {
    this.timeZone = this.timeZoneValue;
    this.ignoreEvents = false;
  },
  watch: {
    timeZoneValue: function (newVal) {
      if (this.timeZone !== newVal) {
        this.timeZone = newVal;
      }
    },
  },
  computed: {
    datePickerShortcuts() {
      return datePickerShortcuts;
    },
    componentValue() {
      let startDate = null;
      let endDate = null;
      let start = null;
      let end = null;
      if (this.dateModel && this.dateModel.start) {
        start = this.dateModel.start;
        startDate = moment.tz(start, this.timeZone).toDate();
      }
      if (this.dateModel && this.dateModel.end) {
        end = this.dateModel.end;
        endDate = moment.tz(end + "T23:59:59.000", this.timeZone).toDate();
      }
      return {
        start: start,
        end: end,
        timezone: this.timeZone,
        startDate: startDate || null,
        endDate: endDate || null,
      };
    },
    customTimezoneList() {
      if (this.timezones) {
        return [...this.timezones].sort();
      }

      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const utcTimezone = "UTC";
      if (this.extraTimezones) {
        const set = new Set(this.extraTimezones);
        set.delete(browserTimezone);
        set.delete(utcTimezone);
        if (set.size === 1) {
          return [...set, browserTimezone, utcTimezone];
        }
        return [browserTimezone, utcTimezone, ...[...set].sort()];
      }

      return [browserTimezone, utcTimezone];
    },
  },
  methods: {
    onTimeZoneInput(value) {
      if (typeof value === 'string') {
        this.timeZone = value;
        this.emitEvent();
      }
    },
    onDatePickerInput(value) {
      if (!value){
        // the user clicked the x button to clear the date
        this.emitEvent();
      }
    },
    onValidate() {
      this.emitEvent();
    },
    emitEvent() {
      if (this.ignoreEvents) {
        return;
      }
      this.$emit("input", this.componentValue);
    },
  },
};
</script>

<style scoped lang="scss"></style>
