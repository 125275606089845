import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate

export const photographersDataFields = {
    'email': {
        key: 'email',
    },
    'count': {
        key: 'count',
    },
    'delivered_count': {
        key: 'delivered_count',
    },
    'medias_count': {
        key: 'medias_count',
    },
    'total_amount': {
        key: 'total_amount',
    },
    'actions': {
        key: 'actions',
        sorter: false,
        filter: false,

    }
}

export const photographerDetailDataFields = {
    'created_at': {
        key: 'created_at',
        label: $pgettext('stats', 'Date time upload'),
        _style: 'width:280px'
    },
    'count': {
        key: 'count',
        label: $pgettext('stats', 'Uploaded photos'),
        _style: 'width:200px'
    },
    'valid_photos': {
        key: 'valid_photos',
        label: $pgettext('stats', 'Valid photos'),
        _style: ''
    },
    'notes': {
        key: 'notes',
        label: $pgettext('stats', 'Notes'),
        _style: ''
    },
    'recognition': {
        key: 'recognition',
        label: $pgettext('stats', 'AI Status'),

    },
    'medias_original_uplindustryoaded': {
        key: 'medias_original_uploaded',
        label: 'HD',
        _style: 'width:140px'
    },
}

export const industriesKeys = {
    ENDURANCE: 'endurance',
    RESORT: 'resort',
    CORPORATE: 'corporate',
    STADIUM: 'stadium',
    OTHER: 'other'

}

export const industriesConfig = {
    [industriesKeys.ENDURANCE]: {
        label: $pgettext('stats', 'Endurance'),
    },
    [industriesKeys.RESORT]: {
        label: $pgettext('stats', 'Hospitality'),
    },
    [industriesKeys.CORPORATE]: {
        label: $pgettext('stats', 'Corporate'),
    },
    [industriesKeys.STADIUM]: {
        label: $pgettext('stats', 'Fan'),
    },
    [industriesKeys.OTHER]: {
        label: $pgettext('stats', 'Other'),
    },
}

export const INDUSTRIES_ER = [
    industriesKeys.ENDURANCE,
    industriesKeys.RESORT
]

export function getIndustryConfig(industry) {
    return industriesConfig[industry]
}

export const datePickerShortcuts = [
    {key: 'today', label: $pgettext('datePicker', 'Today'), value: 0},
    {key: 'thisWeek', label: $pgettext('datePicker', 'This week'), value: 'isoWeek'},
    {key: 'lastWeek', label: $pgettext('datePicker', 'Last week'), value: '-isoWeek'},
    {key: 'last7Days', label: $pgettext('datePicker', 'Last 7 days'), value: 7},
    {key: 'last30Days', label: $pgettext('datePicker', 'Last 30 days'), value: 30},
    {key: 'thisMonth', label: $pgettext('datePicker', 'This month'), value: 'month'},
    {key: 'lastMonth', label: $pgettext('datePicker', 'Last month'), value: '-month'},
    {key: 'thisYear', label: $pgettext('datePicker', 'This year'), value: 'year'},
    {key: 'lastYear', label: $pgettext('datePicker', 'Last year'), value: '-year'}
]


export const checkinByTagOptions = [
    {value: '', label:  $pgettext('stats', 'All')},
    {value: 'ma', label:  $pgettext('stats', 'Manual')},
    {value: 'qr', label:  $pgettext('stats', 'QR code')},
    {value: 'dl', label:  $pgettext('stats', 'Direct link')}
]