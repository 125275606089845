export const arrayToObject = (array) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

export const getPercentage = (total, value) => {
    if (total && value) {
        return (100 * value) / total || 0
    } else {
        return 0
    }
}

export const getAverage = (total, value) => {
    if (total && value) {
        return total / value || 0
    } else {
        return 0
    }
}