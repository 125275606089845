import client_core from "@/services/clients/client_core";

const StatsService = {
    abortControllers: {},

    // TODO: Deprecated stats, remove?
    getMediaCountsByPhotographer(picaServiceId) {
        return client_core.get(`/dashboard/v1/stats/pica-services/${picaServiceId}/media-counts-by-photographer/`)
    },

    getStats(picaServiceId) {
        return client_core.get(`/dashboard/v1/stats/pica-services/${picaServiceId}/`)
    },

    getStatsUploadTasks(picaServiceId) {
        return client_core.get(`/dashboard/v1/stats/pica-services/${picaServiceId}/upload-tasks/`)
    },

    // New stats 2023
    getAbortController(ref) {
        if (this.abortControllers[ref]) this.abortControllers[ref].abort()
        this.abortControllers[ref] = new AbortController();
        return this.abortControllers[ref]
    },

    getPicaServices(qs = ''){
        return client_core.get(`dashboard/v2/stats/pica-services/${qs}`)
    },

    searchPicaServices(params) {
        return client_core.get(`dashboard/v2/stats/pica-services/search/`, {params})
    },

    /*getOrdersCountByDate(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getOrdersCountByDate.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/orders/counts-by-date/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/orders/counts-by-date/`, bodyParams, params)
        }

    },
    getOrdersSummary(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getOrdersSummary.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/orders/summary/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/orders/summary/`, bodyParams, params)
        }

    },
    getOrdersCampaigns(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getOrdersCampaigns.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/orders/campaign/`, params)
        } else {
            //return client_core.post(`dashboard/v2/stats/pica-services/orders/summary/`, bodyParams, params)
        }

    },
   getOrdersBoughtPhotos(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getOrdersBoughtPhotos.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/orders/bought-photos/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/orders/bought-photos/`, bodyParams, params)
        }

    },*/

    /*
    getCheckinsCountByDate(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getCheckinsCountByDate.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/checkins/counts-by-date/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/checkins/counts-by-date/`, bodyParams, params)
        }
    },

    getCheckinsTagsSummary(picaServiceId, params) {
        let abortController = this.getAbortController(this.getCheckinsTagsSummary.name)
        params = {params, signal: abortController.signal}

        return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/checkins/tags/summary/`, params)
    },

    getCheckinsSummary(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getCheckinsSummary.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/checkins/summary/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/checkins/summary/`, bodyParams, params)
        }
    },

    getCheckinsGroupBy(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getCheckinsGroupBy.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/checkins/group-by/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/checkins/group-by/`, bodyParams, params)
        }
    },

    getUploadsSummary(picaServiceId, params) {
        let abortController = this.getAbortController(this.getUploadsSummary.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/uploads/summary/`, params)
        }

    },

    getOrdersAmountByPhotographer(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getOrdersAmountByPhotographer.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/orders/amounts-by-photographer/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/orders/amounts-by-photographer/`, bodyParams, params)
        }

    },
    getUploadsCountByPhotographer(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getUploadsCountByPhotographer.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/uploads/counts-by-photographer/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/uploads/counts-by-photographer/`, bodyParams, params)
        }

    },
    getUploadsCountByUploadTask(picaServiceId, params, bodyParams) {
        let abortController = this.getAbortController(this.getUploadsCountByUploadTask.name)
        params = {params, signal: abortController.signal}

        if (picaServiceId) {
            return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/uploads/counts-by-upload-task/`, params)
        } else {
            return client_core.post(`dashboard/v2/stats/pica-services/uploads/counts-by-upload-task/`, bodyParams, params)
        }
    },


    getReleases(picaServiceId, params) {
        //let abortController = this.getAbortController(this.getReleases.name)
        //params = {params, signal: abortController.signal}

        return client_core.get(`dashboard/v2/stats/pica-services/${picaServiceId}/releases/`, {params})
    },*/


    // Sales report
    getSalesTotal(params) {
        let abortController = this.getAbortController(this.getSalesTotal.name)
        params = {params, signal: abortController.signal}

        return client_core.get(`dashboard/v2/stats/sales/summary/`, params)
    },
    getPhotoServiceTotals(photoServiceId, params) {
        let abortController = this.getAbortController(this.getPhotoServiceTotals.name)
        params = {params, signal: abortController.signal}

        return client_core.get(`dashboard/v2/stats/sales/photo-service/${photoServiceId}/summary/`, params)
    },
    getPhotoServiceEventsTotals(photoServiceId, params) {
        let abortController = this.getAbortController(this.getPhotoServiceEventsTotals.name)
        params = {params, signal: abortController.signal}

        return client_core.get(`dashboard/v2/stats/sales/photo-service/${photoServiceId}/`, params)
    },
}

export default StatsService


