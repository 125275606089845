import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export const ordersFields = {
    'order_id': {
        key: 'order_id',
        label: 'ID',
        _style: 'width:5%',
        sorter: true,
    },
    'order_number': {
        key: 'order_number',
        label: $gettext('Order number'),
        sorter: false,
    },
    'created_at': {
        key: 'created_at',
        label: $gettext('Created at'),
        sorter: true,
        _style: 'min-width:200px'
    },
    'event_name': {
        key: 'event_name',
        label: $gettext('Event'),
        sorter: false,
        _style: 'min-width:200px'
    },
    'user': {
        key: 'user',
        label: $gettext('User'),
        sorter: false,
    },
    'picacode': {
        key: 'picacode',
        label: $gettext('Pica code'),
        sorter: false,
    },
    'payment_method': {
        key: 'payment_method',
        label: $gettext('Payment method'),
        sorter: false,
    },
    'during_stay': {
        key: 'during_stay',
        label: $gettext('During stay'),
        sorter: false,
        filter: false
    },
    'status': {
        key: 'status',
        label: $gettext('Status'),
        sorter: false,
        _style: 'min-width:100px'
    },
    'order_type': {
        key: 'order_type',
        label: $gettext('Type'),
        sorter: false,
        filter: false,
        _style: 'min-width:140px'
    },
    'paid_at': {
        key: 'paid_at',
        label: $gettext('Paid at'),
        sorter: true,
        _style: 'min-width:175px'
    },

    'total_amount': {
        key: 'total_amount',
        label: $gettext('Amount'),
        filter: false,
    },

    'amount_tax': {
        key: 'amount_tax',
        label: $gettext('Amount tax'),
        filter: false,
        sorter: false,
    },
    'platform_fee_total': {
        key: 'platform_fee_total',
        label: $gettext('Platform fee'),
        filter: false,
        sorter: false,
    },
    'payment_transaction_fee': {
        key: 'payment_transaction_fee',
        label: $gettext('Card Fee (shared)'),
        sorter: false,
        filter: false,
    },
    'payment_transaction_fee_on_pica': {
        key: 'payment_transaction_fee_on_pica',
        label: $gettext('Card Fee (GetPica)'),
        sorter: false,
        filter: false,
    },
    'amount_net_photo_service': {
        key: 'amount_net_photo_service',
        label: $gettext('Net amount'),
        filter: false,
        sorter: false,
    },
    'actions': {
        key: 'actions',
        label: '',
        sorter: false,
        filter: false,
        _classes: 'sticky-column',
    },
}