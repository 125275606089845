<template>
  <small v-translate translate-context="info" :translate-params="{ timezone: timezone }">
    Time Zone: <strong>%{timezone}</strong>
  </small>
</template>

<script>

export default {
  name: "TimeZoneInfo",
  props: {
    timezone: {
      type: String,
      required: false,
    },
  }

}
</script>